import React, {useRef} from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/SEO';
import SectionIntro from '../../components/SectionIntro';
import {Link, graphql, useStaticQuery} from 'gatsby';
import Button from '../../components/Button';
import russelCurious from '../../images/product/russel-curious.svg';
import {FeaturesButtons} from '../../components/systems';
import {StaticImage} from 'gatsby-plugin-image';
import LinkButton from '../../components/LinkButton';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {getImage} from 'gatsby-plugin-image';

const IntroUseCase = ({title}) => {
  const titleClassName = `leading-11 md:leading-12 font-bold text-3xl md:text-5xl text-left text-blue-royal mb-8`;
  return (
    <>
      <div className={titleClassName}>{title}</div>
    </>
  );
};

const Cases = () => {
  const scrollRefCase01 = useRef(null);
  const scrollRefCase02 = useRef(null);
  const scrollRefCase03 = useRef(null);

  const buttons = [
    {label: 'Delegated Management', ref: null},
    {label: 'On-Call Emergency Access', ref: null},
    {label: 'IdP Frontend  by SSO', ref: null},
  ];

  const scrollRefTable = {
    0: scrollRefCase01,
    1: scrollRefCase02,
    2: scrollRefCase03,
  };

  const handleClick = ({index}) => {
    const barHeight = '80px';
    scrollRefTable[index].current.style.scrollMargin = barHeight;
    scrollRefTable[index].current.scrollIntoView({behavior: 'smooth'});
  };

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
      placeholderImage: file(relativePath: {eq: "bg/bg-section-header.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);

  const {backgroundImage, placeholderImage} = data;
  const bgImage = convertToBgImage(getImage(backgroundImage));
  const bgImageRussel = convertToBgImage(getImage(placeholderImage));

  return (
    <Layout
      seo={
        <Seo
          title="Use Cases | Trustle"
          description="See how Trustle dives deep to surface important data and keeps your systems secure."
        />
      }
    >
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <section className="pt-16 pb-8 ">
          <div className="max-w-5xl px-5 pt-0 mx-auto text-center">
            <SectionIntro
              hero="Use Cases"
              title="Why you need us now"
              description="Trustle customers improve security, get more work done, and save money while doing it."
              isPageHeader
            />
          </div>

          <div className="flex flex-col justify-between px-5 pt-0 mx-auto mb-16 text-center lg:flex-row max-w-7xl">
            <div className="pb-4 max-w-[440px]">
              <h3 className="text-2xl font-bold text-transparent xl:text-3xl leading-11 bg-trustle-gradient bg-clip-text">
                208 days
              </h3>
              <p>The average time it takes companies to identify an identity data breach.</p>
            </div>
            <div className="pb-4 max-w-[440px]">
              <h3 className="text-2xl font-bold text-transparent xl:text-3xl leading-11 bg-trustle-gradient bg-clip-text">
                1/3 of all breaches
              </h3>
              <p>are caused by over entitled access.</p>
            </div>
            <div className="pb-4 max-w-[440px]">
              <h3 className="text-2xl font-bold text-transparent xl:text-3xl leading-11 bg-trustle-gradient bg-clip-text">
                $3.6 million
              </h3>
              <p>The average amount companies saved in security costs that used least privilege access.</p>
            </div>
          </div>

          <div className="mb-16 md:mb-32 text-sm w-[250px] mx-auto font-bold text-center">
            <LinkButton url="/demo" className="z-10 px-2 py-2 my-3 md:my-0 md:py1 md:mb-0">
              Schedule a Demo
            </LinkButton>
          </div>
          <div className="max-w-[1360px] mx-auto">
            <FeaturesButtons dataButtons={buttons} handleClick={handleClick} />
          </div>
        </section>
      </BackgroundImage>
      {/* Section 1 */}
      <div ref={scrollRefCase01}></div>
      <section className="bg-[#c2e5ff] bg-opacity-20 py-16 lg:py-32 relative">
        <div className="max-w-[1360px] mx-auto text-blue text-center">
          <div className="flex flex-col items-center justify-between mx-8 mb-8 xl:flex-row md:items-start md:mb-16">
            <div className="pb-8 lg:pb-0 max-w-[673px]">
              <IntroUseCase title="Delegated Management, Requests, and Approvals" />
            </div>
            <div className="xl:max-w-[530px] pt-5 text-blue">
              <h2 className="mb-4 text-lg font-bold text-left sm:text-xl">Problem Statement</h2>
              <p className="className={`md:text-lg text-left m-auto text-blue`}">
                We are a startup with a small team, we can’t introduce a complex process to enable our devs to do their
                jobs. <br></br>
                <br></br>How can I implement a JIT access solution without bottlenecking the access requestors?
              </p>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="pb-8 max-w-[530px] xl:max-w-[700px] absolute right-0 hidden justify-end 2xl:flex 4xl:hidden">
            <StaticImage src="../../images/usecase/usecase-screenshot-01.png" alt="screenshot trustle packs" />
          </div>
        </div>
        <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center ">
          <div className="mb-8 md:mb-16">
            <div className="flex flex-col items-end justify-between xl:items-center xl:flex-row ">
              <div className="xl:max-w-[673px] pt-5 text-blue mb-16 mx-8">
                <div className="mb-4 font-bold text-left md:text-2xl sm:text-xl">How Does Trustle Solve for This?</div>
                <p className="className={`md:text-lg text-left m-auto  text-blue`}">
                  <b>Trustle Packs</b>
                  <br></br>
                  Trustle allows a designated Owner to grant and revoke a set of entitlements called a Trustle Pack. We
                  allow Managers to do this now, which is a form a delegation in our policies. <br></br>
                  <br></br>With Trustle, you can take a distributed, proactive context-based approach to multi-cloud
                  risk management.<br></br>
                  <br></br> Your teams have limited time and resources, and can't have JIT access workflows slow them
                  down.
                  <br></br>
                  <br></br>Trustle helps accelerate the least privilege access request workflow by allowing you to
                  delegate the authority to trusted individuals within your organization who have the necessary context
                  to approve or deny tasks. This gives you better control over your cloud environment while
                  simultaneously empowering your employees to be more productive.<br></br>
                  <br></br> Want to request access to resources for a co-worker who will be helping you troubleshoot an
                  issue?
                </p>
              </div>
              <div className="pb-8 max-w-[530px] xl:max-w-[740px] 2xl:hidden 4xl:flex">
                <StaticImage src="../../images/usecase/usecase-screenshot-01.png" alt="screenshot trustle packs" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 */}
      <div ref={scrollRefCase02}></div>
      <section className="relative py-16 bg-white bg-opacity-20 lg:py-32">
        <div className="max-w-[1360px] mx-auto text-blue text-center">
          <div className="flex flex-col items-center justify-between mx-8 mb-8 xl:flex-row md:items-start md:mb-16">
            <div className="pb-8 lg:pb-0 max-w-[673px]">
              <IntroUseCase title="On-Call Emergency Access" />
            </div>
            <div className="xl:max-w-[530px] pt-5 text-blue">
              <h2 className="mb-4 text-lg font-bold text-left sm:text-xl">Problem Statement</h2>
              <p className="className={`md:text-lg text-left m-auto text-blue`}">
                Our Operations team needs immediate access to production environments when a service disruption occurs.
                <br></br>
                <br></br>How can I automate this access approval without the risk of standing access for the entire
                team?
              </p>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="pb-8 max-w-[530px] xl:max-w-[700px] absolute right-0 hidden justify-end 2xl:flex 4xl:hidden">
            <StaticImage src="../../images/usecase/usecase-screenshot-02.png" alt="screenshot trustle packs" />
          </div>
        </div>
        <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center ">
          <div className="mb-8 md:mb-16">
            <div className="flex flex-col items-end justify-between xl:items-center xl:flex-row ">
              <div className="xl:max-w-[673px] pt-5 text-blue mb-16 mx-8">
                <div className="mb-4 font-bold text-left md:text-2xl sm:text-xl">
                  When an on-call event occurs, give the right people the access they need, then remove it once the
                  event is remediated.
                </div>
                <p className="className={`md:text-lg text-left m-auto  text-blue`}">
                  With Trustle's multi-cloud context-based access management workflow solution, enterprises can
                  streamline time-based, on-call duty access.<br></br>
                  <br></br>On-call events are unexpected and staff must be prepared to troubleshoot and remediate nearly
                  any issue.  The one issue they should never have to think about fixing is making sure they have the
                  proper access required to do their job. <br></br>
                  <br></br>By approving least privilege access to production systems at rotation time, while enforcing
                  zero standing access to those systems until an on-call event is triggered, Trustle automates on-call
                  access, ensuring that engineers have proper access to resources during incidents. This reduces
                  incident downtime and increases the security of your cloud environment. <br></br>
                  <br></br>In addition, to comply with security and privacy regulations like CIS, SOC2, and HIPAA,
                  Trustle also provides a paper trail of on-call-related incident access in the form of workflow logs
                  and compliance reports.
                </p>
              </div>
              <div className="pb-8 max-w-[530px] xl:max-w-[740px] 2xl:hidden 4xl:flex">
                <StaticImage src="../../images/usecase/usecase-screenshot-02.png" alt="screenshot trustle packs" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 3 */}
      <div ref={scrollRefCase03}></div>
      <section className="bg-[#F8F9FC] py-16 xl:pt-32 relative lg:pb-20 2xl:pb-32">
        <div className="max-w-[1360px] mx-auto text-blue text-center">
          <div className="flex flex-col items-center justify-between mx-8 mb-8 xl:flex-row md:items-start md:mb-16">
            <div className="pb-8 lg:pb-0 max-w-[673px]">
              <IntroUseCase title="IdP Fronted by SSO" />
            </div>
            <div className="xl:max-w-[530px] pt-5 text-blue">
              <h2 className="mb-4 text-lg font-bold text-left sm:text-xl">Problem Statement</h2>
              <p className="className={`md:text-lg text-left m-auto text-blue`}">
                I use AWS SSO with an external IdP like Okta… how can I grant a specific user access to a specific IdP
                group fronted by AWS SSO? <br></br>
              </p>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="pb-8 max-w-[530px] xl:max-w-[700px] absolute right-0 hidden justify-end 2xl:flex 4xl:hidden">
            <StaticImage src="../../images/usecase/usecase-screenshot-03.png" alt="screenshot trustle packs" />
          </div>
        </div>
        <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center ">
          <div className="mb-8 md:mb-16">
            <div className="flex flex-col items-end justify-between xl:items-center xl:flex-row ">
              <div className="xl:max-w-[673px] pt-5 text-blue mb-16 mx-8">
                <div className="mb-4 font-bold text-left md:text-2xl sm:text-xl">Single Sign On?  Single Solution.</div>
                <p className="className={`md:text-lg text-left m-auto pb-6 text-blue`}">
                  Trustle works seamlessly in environments which utilize Single Sign On (SSO).
                </p>
                <p className="className={`md:text-lg text-left m-auto pb-6 text-blue`}">
                  Whether your IdP and SSO system are one in the same (for example, if you use Okta for App Login and
                  IdP), or if you are using SCIM to synchronize your user and group data to an external SSO, like in the
                  case of an Okta / AWS IAM Identify Center (AWS SSO) integration
                </p>
                <p className="className={`md:text-lg text-left m-auto pb-6 text-blue`}">
                  <b>Trustle’s got you covered!</b>
                </p>
              </div>
              <div className="pb-8 max-w-[530px] xl:max-w-[740px] 2xl:hidden 4xl:flex">
                <StaticImage src="../../images/usecase/usecase-screenshot-03.png" alt="screenshot trustle packs" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Banner Russel */}
      <section className="py-28 ">
        <BackgroundImage Tag="section" {...bgImageRussel} preserveStackingContext>
          <div className="relative flex flex-col items-center justify-center w-full pt-12 pb-8 mb-8 text-white md:flex-row lg:max-w-4xl lg:mx-auto filter drop-shadow-2xl">
            <div className="relative flex flex-col-reverse items-center justify-center mt-4 md:ml-8 md:mt-0">
              <div>
                <h3 className="pb-4 text-3xl font-bold text-center md:text-2xl">Schedule a demo today!</h3>
                <div className="px-4 text-center pb-7 text-md md:text-lg">
                  Get an inside look at Trustle. Schedule a demo with us today.
                </div>
                <div className="w-full px-4 mx-auto text-center">
                  <Link to="/demo">
                    <Button label="Schedule Demo" variant="secondary" />
                  </Link>
                </div>
              </div>
              <div className="relative flex flex-col justify-between h-full md:absolute md:top-24 md:-left-8">
                <img
                  src={russelCurious}
                  alt="Russell Trustle curious"
                  className="max-w-[80px] md:max-w-[110px] lg:max-w-[170px]"
                />
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>
    </Layout>
  );
};

export default Cases;
